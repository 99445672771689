<template>
	<PreferencesList />
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'ProfileDocumentsView',
	components: {
		PreferencesList: () => ({
			component: import('@/components/Documents/Profile/PreferencesList')
		})
	},
	mixins: [DocumentsManagerModuleGuard]
}
</script>
